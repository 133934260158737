import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./Pagination.module.css";

export const TablePagination = ({
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  pageIndex,
  pageOptions,
  canNextPage,
  pageCount,
}) => {
  const [t] = useTranslation();
 
  return (
    <div className={`${classes.pagination}`}>
      <div className={classes.pagination_section_2}>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {t("First Page")}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {t("Previous Page")}
        </button>
        <span>
          {t("Page")}
          <strong>
            {pageIndex + 1} {t("of")} {pageOptions.length}
          </strong>
        </span>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {t("Next Page")}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {t("Last Page")}
        </button>
      </div>
    </div>
  );
};

export const SearchPagination = ({
  pageIndex,
  gotoPage,
  setPageSize,
  pageSize,
}) => {
  const [t] = useTranslation();

  return (
    <div className={`${classes.pagination}`}>
      <div className={classes.pagination_section_1}>
        <input
          placeholder={t("general.number of page")}
          type="number"
          min="1"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
        />
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {t("general.Show")} {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
