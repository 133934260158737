import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import classes from "./index.module.css";
import { useTranslation } from "react-i18next";
import { Flex } from "@chakra-ui/react";



const Forgotpassword = ({setForgotPassword}) => {
  const [t]=useTranslation()
  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("Invalid email")).required(t("Email is required")),
  });
  
  const initialValues = {
    email: "",
  };
  const handleSubmit = (values) => {
    // Handle form submission here
    console.log(values);
  };

  return (
    <div>
    <Flex flexDirection="column" justifyContent={"start"} alignItems="center" marginInlineStart={"10px"}>
        <h2 className={classes.forgot_your_password}>{t("general.Forgot your password")}</h2>
        <p className={classes.enter_email}>{t("general.Please enter your e-mail")}</p>
    </Flex>

    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className={classes.formForgotPassword}>
        <div>
          <Field
            type="email"
            id="email"
            name="email"
            placeholder={t("general.Email")}
            className={classes.email}
          />
          <ErrorMessage
            name="email"
            component="div"
            className={classes.error}
          />
        </div>

        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <button
            className={classes.back}
            onClick={() => setForgotPassword(false)}
          >
            {t('general.Back')}
          </button>
          <button type="submit" className={classes.send}>
            {t("general.Send")}
          </button>
        </Flex> 
      </Form>
    </Formik>
    </div>
  );
};

export default Forgotpassword;
