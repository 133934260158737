import React, { useEffect, useState } from "react";
 import {   useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Input } from "@chakra-ui/react";
 
import TablePatients from "../table/TablePatients";
import ExportToExcel from "../../../sheard components/ExportToExcel";
import axios from "axios";
// import { useFetchDataQuery } from "../../../store/tableData";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

function Patients() {
  const [t] = useTranslation();
  // const { data:tableDataa, error:tableError, isLoading:tableLoading } = useFetchDataQuery([]);
  // const dispatch = useDispatch();
  // const { tableData, loading, error } = useSelector((state) => state.tableData);
  const [editbleData, setEditbleData] = useState([]);
   const { updateNotaSlice } = useSelector((state) => state);
 
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //    dispatch(fetchPatientTableData());
  // }, [dispatch]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/Patient/GetPatients`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("access-token")
          )}`,
        },
      })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateNotaSlice) {
      let newData = data.map((item) => {
        return item.id === updateNotaSlice.id
          ? { ...item, isNew: updateNotaSlice.isNew ,lastUpdate: updateNotaSlice.lastUpdate }
          : item;
      });
      setData(newData);
    }
  }, [updateNotaSlice,data]);

  // const [isStutes,setStutes] =useState("")
  // console.log("34664367",isStutes)


  useEffect(() => {
    if (data && data.length > 0) {
      const newArray =
        data &&
        data.map((item) => {
          return {
            id: item.id,
            name: item.name,
            phoneNumber: item.phoneNumber,
            gender: item.gender,
            stutes: item.isNew ? "new" : "communicated",
            communicationTime: item.communicationTime,
            birthDate: item.birthDate,
            city: item.city,
            clinic:item.clinic,
            knowAboutus:item.knowAboutus,
            service: item.service,
            nearestBranch: item.nearestBranch,
            signDate: item.newDate,
            lastUpdate: item.lastUpdate,
          };
        });
      setEditbleData(newArray);
    }
  }, [data]);

  // useEffect(()=>{
  //   if(updateNotaSlice){
  //      editbleData.map(item =>{    
  //       return setStutes(item.id === updateNotaSlice.id && item.stutes)
  //     })
 
  //   }

  // },[editbleData,updateNotaSlice])
  // search state
  const [query, setQuery] = useState("");
  const handelQuery = (e) => {
    setQuery(e.target.value);
  };

  // filter search
  const keys = ["name", "phoneNumber", "gender", "city", "nearestBranch","stutes"];
  const search = (data) => {
    return data.filter((item) =>
      keys.some((key) => item[key].toString().toLowerCase().includes(query))
    );
  };

  return (
    <div>
      <ExportToExcel data={editbleData} />
      <Input
        placeholder={t("general.search")}
        value={query}
        onChange={handelQuery}
        marginBottom="10px"
      />
      <TablePatients
        loading={loading}
        error={error}
        tableData={editbleData && search(editbleData)}
 
      />
    </div>
  );
}

export default Patients;
