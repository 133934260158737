import React, { useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import classes from "./index.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { insertUserData } from "../../../store/login";
import { useNavigate } from "react-router-dom";

const FormLogin = ({ setForgotPassword }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    userName: Yup.string().required(t("username is required")),
    password: Yup.string().required(t("password is required")),
  });
  const { users, loading, message } = useSelector((state) => state.login);

  const initialValues = {
    userName: "",
    password: "",
    // rememberMe: false,
  };

  const handleSubmit = async (values, { resetForm }) => {
    console.log("values",values);
    // Handle form submission here
    await dispatch(insertUserData(values))
      .then((res) => {
        if (res.meta.requestStatus === "fulfilled") navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
    resetForm({ values: "" });
  };

  useEffect(() => {
    if (users && users.roleId) {
      navigate("/");
    }
  }, [users, navigate]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form className={classes.form}>
          <div style={{ width: "100%" }}>
            <Field
              type="text"
              id="userName"
              name="userName"
              placeholder={t("general.User Name")}
              className={classes.username}
            />
            <ErrorMessage
              name="userName"
              component="div"
              className={classes.error}
            />
          </div>

          <div style={{ width: "100%" }}>
            <Field
              type="password"
              id="password"
              name="password"
              placeholder={t("general.Password")}
              className={classes.password}
            />
            <ErrorMessage
              name="password"
              component="div"
              className={classes.error}
            />
          </div>

          <div className={classes.submitdiv}>
            <div>
              <label>
                <Field type="checkbox" name="rememberMe" />
                <span className={classes.rememberMe}>{t("general.Remember Me ?")}</span>
              </label>
            </div>

            <p
              className={classes.forgotpassword}
              onClick={() => setForgotPassword(true)}
            >
              {t("general.Forgot your password ?")}
            </p>

          </div>

          <div >
            <button type="submit" className={classes.submitbutton}>
              {loading ? t("general.Loading") : t("general.LOGIN")}
            </button>
          </div>
        </Form>
      </Formik>
      {message && <div className="text-[red]">{message}</div>}
    </>
  );
};

export default FormLogin;
