import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Flex,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import * as PiIcons from "react-icons/pi";
import * as FiIcons from "react-icons/fi";
import { toggleSidebar } from "../../store/sidebarSlice";

const Navbar = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
   const logOut = async () => {
    localStorage.clear();
    window.location.reload();
  };
  return (
    <Flex
      as="nav"
      paddingInline={4}
      alignItems="center"
      bg="#46A0CD"
      height="70px"
      color="#000"
    >
      <Flex alignItems="center" width="100%" justifyContent="space-between">
        <Button
          type="button"
          bg="transparent"
          p={0}
          _hover={{ bg: "transparent" }}
          onClick={() => dispatch(toggleSidebar())}
        >
          <PiIcons.PiListLight size={28} color="dark" />
        </Button>
        <Flex alignItems="center" gap={3}>
          <Button
            type="button"
            p={0}
            bg="none"
            height="auto"
            _hover={{ bg: "none" }}
            color="dark"
            onClick={() => {
              i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar");
              window.location.reload();
            }}
          >
            <FiIcons.FiGlobe size={20} />
          </Button>

          <Menu>
            <MenuButton
              as={Button}
              p={0}
              bg="none"
              height="auto"
              _hover={{ bg: "none" }}
            >
              <Avatar size="sm" name="Admin" />
            </MenuButton>
            <MenuList p={0} minW="auto">
              <MenuItem>{t("general.Profile")}</MenuItem>
              <MenuItem onClick={logOut}>{t("general.Logout")}</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Navbar;
