import React from "react";
// import { Route, Routes } from "react-router-dom";
import { HashRouter as Router, Routes, Route, Switch } from "react-router-dom";

import NotFound from "./NotFound";
import PrivateRoute from "./PrivateRoute";
import { Main, Patients } from "../featuers/dashbord/pages/index";
import { AdminLogin } from "../featuers/auth/pages/index";

function Routing() {
  return (
       <Routes>
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route path="*" element={<NotFound />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" exact element={<Main />} />
          <Route path="/patients" exact element={<Patients />} />
        </Route>
      </Routes>
   );
}

export default Routing;
