// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// export const fetchPatientTableData = createAsyncThunk(
//   'tableData/fetchPatientTableData',
//   async (_, thunkAPI) => {
//     const { rejectWithValue } = thunkAPI;
//     try {
//       const res = await fetch(`https://clinicapi.mygatein.com/Patient/GetPatients`, {
//         headers: {
//           DataType: "JSON",
//           Authorization: "Bearer " + JSON.parse(localStorage.getItem("access-token")),
//           mode: 'cors',
//           Accept: 'application/json',
//         },
//       });
//       const data = await res.json();
//       return data;
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// export const updatePatientTableData = createAsyncThunk(
//   "tableData/updatePatientTableData",
//   async (id, thunkAPI) => {
//     const { rejectWithValue } = thunkAPI;
//     try {
//       const res = await fetch(`https://clinicapi.mygatein.com/Patient/EditPatient/${id}`, {
//         method: "PUT",
//         body: JSON.stringify(id),
//         headers: {
//           DataType: "JSON",
//           Authorization: "Bearer " + JSON.parse(localStorage.getItem("access-token")),
//           mode: 'cors',
//           Accept: 'application/json',
//         },
//       });
//       const data = await res.json();
//       console.log("data from store", data);
//       return data;
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// const patientsDataTableSlice = createSlice({
//   name: 'tableData',
//   initialState: {
//     tableData: [], // Ensure that this matches the structure of your expected data
//     loading: false,
//     error: null,
//   },
//   reducers: {}, // You can add other reducers here if needed
//   extraReducers: {
//     [fetchPatientTableData.pending]: (state) => {
//       state.loading = true;
//       state.error = null;
//     },
//     [fetchPatientTableData.fulfilled]: (state, action) => {
//       state.loading = false;
//       state.tableData = action.payload; // Ensure that action.payload matches your expected data structure
//     },
//     [fetchPatientTableData.rejected]: (state, action) => {
//       state.loading = false;
//       state.error = action.payload; // Ensure that action.payload contains the error message
//     },
//   },
// });

// export default patientsDataTableSlice.reducer;

// api.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const tableData = createApi({
  baseQuery: fetchBaseQuery({
     baseUrl: baseUrl ,
    prepareHeaders: (headers) => {
      // Add your token to the request headers here
      const token =JSON.parse(localStorage.getItem("access-token"));  
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
 
  }), // Replace with your API endpoint
  endpoints: (builder) => ({
    fetchData: builder.query({
      query: () => "/Patient/GetPatients", // Replace with your actual endpoint
    }),
    // updateData: builder.mutation({
      // query: ({ id, newData }) => ({
      //   url: `your-api-endpoint/${id}`, // Replace with the UPDATE endpoint
      //   method: 'PUT', // Use 'PUT' or 'PATCH' as appropriate
      //   body: newData, // The data you want to update
      // }),
    // }),
  }),
});

export const { 
  useFetchDataQuery, 
  // useUpdateDataMutation
 } = tableData;
