import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Loading from "../../../sheard components/Loading";
import { useTable, useSortBy, usePagination } from "react-table";
import * as moment from "moment";
import axios from "axios";
import {
   SearchPagination,
} from "./table Pagination/Pagination";
import { Box, useDisclosure } from "@chakra-ui/react";
import ModalView from "../components/modal/ModalView";
import CustomPagination from "./table Pagination/CustomPagination";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

function TablePatients({ tableData, loading, error,isStutes }) {
   const [t] = useTranslation();
  //  add option button
  const [modalData, setModalData] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalErrorr, setModalError] = useState(null);
  const [rowId, setRowId] = useState();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchData = (id) => {
    const token = JSON.parse(localStorage.getItem("access-token"));
    setModalLoading(true);
    axios
      .get(`${baseUrl}/Patient/GetSingelPatient/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setModalData(response.data);
        setModalLoading(false);
      })
      .catch((error) => {
        setModalError(error);
        setModalLoading(false);
      });
  };
 
  const handelClick = () => {
     onOpen();
   };

  const Columns = [
    {
      Header: t("general.Name"),
      accessor: "name",
    },
    {
      Header: t("general.Phone Number"),
      accessor: "phoneNumber",
    },
    {
      Header: t("general.Gender"),
      accessor: "gender",
    },
    {
      Header: t("general.BirthDate"),
      accessor: "birthDate",
      Cell: (props) => <div> {moment(props.value).format("DD/MM/YYYY")} </div>,
    },
    {
      Header: t("general.CommunicationTime"),
      accessor: "communicationTime",
    },
    {
      Header: t("general.City"),
      accessor: "city",
    },
    {
      Header: t("general.clinic"),
      accessor: "clinic",
    },

    {
      Header: t("general.Service"),
      accessor: "service",
    },
    {
      Header: t("general.NearestBranch"),
      accessor: "nearestBranch",
    },
    {
      Header: t("general.know Aboutus"),
      accessor: "knowAboutus",
    },
    {
      Header: t("general.Status"),
      accessor: "stutes",
      Cell: (props) => (
        <Box
          color={props.value === "communicated" ? "green" : "red"}
          fontWeight="bold"
        >
          {" "}
          {props.value}{" "}
        </Box>
      ),
    },
    {
      Header: t("general.sign Date"),
      accessor: "signDate",
      Cell: (props) => <div> {props.value && moment(props?.value).format("DD/MM/YYYY HH:mm:ss")  } </div>,

    },
    {
      Header: t("general.last Update"),
      accessor: "lastUpdate",
      Cell: (props) => <div> {props.value ? moment(props?.value).format("DD/MM/YYYY HH:mm:ss"):"---"  } </div>,

    }
  ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => Columns, []);
  const Data = useMemo(() => tableData, [tableData])
 
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Show",
        Header: t("general.Show"),
        Cell: ({ row }) => (
          <button
            onClick={() => handelClick(row.values)}
            style={{
              cursor: "pointer",
              backgroundColor: "#46A0CD",
              color: "white",
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            {t("general.Show")}
          </button>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    setPageSize,
    pageCount,
    pageOptions,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data:Data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
    tableHooks
  );

  if (loading) {
    return (
      <Box margin="auto" padding="50px" width="100px">
        <Loading />
      </Box>
    );
  }

  return (
    <div>
      <section className="table-wrapper">
        <table {...getTableProps()} className="fl-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? "🔽"
                          : "🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {tableData.length === 0 ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" ,padding:"50px"}}>
              {t("general.There are no patients.")}
            </div>

          ) : (

            <tbody {...getTableBodyProps()}>
              {page.map((row) => {

                prepareRow(row);
                return (
                  <tr
                  {...row.getRowProps()}
                  onClick={() => {
                    fetchData(row.cells[0].row.original.id);
                    setRowId(row.cells[0].row.original.id);
                  }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}

  
        </table>
      </section>
 
      
      <CustomPagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
      />

      <SearchPagination
        gotoPage={gotoPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageSize={pageSize}
        loading={loading}
      />

      {/* modal show */}
      <ModalView
        // row={row}
        rowId={rowId}
        modalErrorr={modalErrorr}
        modalLoading={modalLoading}
        modalData={modalData && modalData}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        // isStutes={isStutes}
      />
    </div>
  );
}

export default TablePatients;
