import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
// fetching
export const insertUserData = createAsyncThunk("user/insertUserData",
  async (values, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
 
      const res = await fetch(`${baseUrl}/Admin/Login`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(values),
        mode: 'cors'

      })
      console.log(res)
      const data = await res.json();
      console.log("data",data)

      localStorage.setItem(
        "current-user",
        JSON.stringify(data)
      );
      localStorage.setItem(
        "access-token",
        JSON.stringify(data.token)
      );
       return data
    } catch (e) {
      return rejectWithValue(e.message);
    }
    
  }
)

const initialState = {
  users:  localStorage.getItem("current-user")
  ? JSON.parse(localStorage.getItem("current-user"))
  : {},
  loading: null,
  error: null,
  message:""
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: {

    [insertUserData.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },

    [insertUserData.fulfilled]: (state, action) => {
      const responseMessage = action.payload.message;
      console.log("responseMessage",responseMessage) 
      state.users = action.payload;
      state.loading = false;
      state.message=action.payload.message
    },

    [insertUserData.rejected]: (state, action) => {
      toast.error('Please fill in all required fields correctly and try again!', {
        position: 'botton-right',
        autoClose: 1000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      
      state.error = action.payload;
      state.loading = false;
    },
  }

})

export default userSlice.reducer;






