import { Button, Text, SimpleGrid, Flex, Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import useDataFetcher from "../../../../sheard components/hooks/useDataFetcher";
import Loading from "../../../../sheard components/Loading";
import classes from "./index.module.css";

function CardDashbord() {
  const { i18n, t } = useTranslation();

  let lang = i18n.language;
  const { data, loading, error } = useDataFetcher(
    `Patient/GetCardDetails?direction=${lang}`
  );

  if (loading) {
    return (
      <Box textAlign={"center"}>
        <Loading />
      </Box>
    );
  }

  if (error) {
    return <div style={{ color: "red" }}>Error: {error.message}</div>;
  }

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 4 }} spacing={4}>
        {/* {data.map((card) => (
           <Flex key={card.id} bg={card.background} justifyContent="space-between" padding="10px"  borderRadius={"24px"}>
            <div width="50%">
              <img
                width="150px"
                height="100%"
                src={card.image}
                alt="Caffe Latte"
                className={classes.cradimg}

              />
            </div>
            <Flex width="50%" flexDirection="column" justifyContent="end">
              <Box>
                <Text py="2" fontSize="50px" color="white">
                  {card.numOfPatients}
                </Text>
                <Text py="2" fontSize="16px" color="white">
                  {card.title}
                </Text>
              </Box>
              <Button variant="solid" bg="#00000040"  colorScheme="blue" marginTop="20px" >
                {t("general.more")}
              </Button>
            </Flex>
            
          </Flex>
         ))} */}
        {data.map((card) => (
          <Flex
            key={ card.title }
            bg={card.background}
            justifyContent="space-between"
            padding="10px"
            borderRadius={"24px"}
          >
            <div width="50%">
              <img
                width="150px"
                height="100%"
                src={card.image}
                alt="Caffe Latte"
                className={classes.cradimg}
              />
            </div>
            <Flex width="50%" flexDirection="column" justifyContent="end">
              <Box>
                <Text py="2" fontSize="50px" color="white">
                  {card.numOfPatients}
                </Text>
                <Text py="2" fontSize="16px" color="white">
                  {card.title}
                </Text>
              </Box>
              <Button
                variant="solid"
                bg="#00000040"
                colorScheme="blue"
                marginTop="20px"
              >
                {t("general.more")}
              </Button>
            </Flex>
          </Flex>
        ))}
      </SimpleGrid>
    </>
  );
}

export default CardDashbord;
