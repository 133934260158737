const routes = {
  dashboard_general: "/",
  dashboard_branches: "dashboard/branches",
  dashboard_inventory: "dashboard/inventory",
  dashboard_call_center: "dashboard/call_center",
  orders: "orders",
  clients: "clients",
  management_more: "management_more",
  bump: "bump",
  hr: "hr",
  general_accounts: "general_accounts"
};

export default routes;