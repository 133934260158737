import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import useDataFetcher from "../../../../sheard components/hooks/useDataFetcher";
import Loading from "../../../../sheard components/Loading";

const Chart = () => {
  const { i18n } = useTranslation();

  let lang = i18n.language;
  const { data, loading, error } = useDataFetcher(
    `Patient/GetChartDetails?direction=${lang}`
  );

  if (loading) {
    return (
      <Flex justifyContent={"center"} alignItems="center" marginTop={"300px"}>
        <Loading />
      </Flex>
    );
  }

  if (error) {
    return <div style={{ color: "red" }}>Error: {error.message}</div>;
  }

  let style = {
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  };

  return (
    <SimpleGrid
      columns={{ base: 1, md: 2, lg: 2, xl: 4 }}
      spacing={4}
      marginTop="50px"
      marginBottom="20px"
    >
      {data.map((data, index) => {
        const seriesData = data[1];
        const categories = data[2];
        const options = {
          chart: {
            type: "bar",
          },
          xaxis: {
            categories: categories,
          },
        };

        const chartData = [
          {
            name: `Series ${index + 1}`,
            data: seriesData,
          },
        ];

        return (
          <Box
            key={index}
            className="chart-container"
            style={style}
            marginBottom={"20px"}
            borderRadius="10px"
            padding={"10px"}
          >
            <Text
              textAlign={"center"}
              fontSize="xl"
              color="#46a0cd"
              fontWeight="bold"
              key={index}
            >
              {data[0]}
            </Text>

            <ReactApexChart
              options={options}
              series={chartData}
              type="bar"
              height={350}
            />
          </Box>
        );
      })}
    </SimpleGrid>
  );
};

export default Chart;
